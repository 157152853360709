import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import Layout from "../components/Layout";
import { getIsUserAuthenticated } from "../services/auth";
import { loginRequest } from "../msal/authConfig";

import "../assets/css/main.scss";

const Forums = () => {
  const { instance } = useMsal();

  const isAuthenticated = getIsUserAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      location.href = 'http://discourse.florabank.org.au';
    }
  }, [])

  return (
    <>
    {
      isAuthenticated ? <></> :
      <Layout>
        <Container className="forums">
          <p>
            The Florabank Forum is the online meeting place for the Florabank community.  It’s where community 
            members can learn, teach and be inspired to build a more vibrant, sustainable and collaborative industry. 
            Sign in to access the forum and fully participate in the community.
          </p>
          <Button className="btn btn-success" onClick={(() => instance.loginRedirect(loginRequest))}>Sign In</Button>
        </Container>
      </Layout>
    }
    </>
  );
};

export default Forums;